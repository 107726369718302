<template>
  <div class="hold-transition">
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Rutas</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Administrativo</li>
                  <li class="breadcrumb-item active">General</li>
                  <li class="breadcrumb-item active">Rutas</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header pt-2 pb-2">
                <div class="row">
                  <div class="col-md-11"></div>
                  <div class="col-md-1">
                    <div class="btn-group float-right">
                      <!-- SE LLAMA A LA VISTA PARA UNA TABLA DINAMICA -->
                      <button
                        type="button"
                        class="btn btn-info"
                        @click="pivotTable()"
                        v-if="$store.getters.can('admin.rutas.pivotTable')"
                      >
                        <i class="fas fa-scroll"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal_form_ruta_export"
                        v-if="$store.getters.can('admin.rutas.export')"
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="create()"
                        v-if="$store.getters.can('admin.rutas.create')"
                      >
                        <i class="fas fa-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <!-- /.card-header -->
              <div class="card-body p-0">
                <table
                  id="rutas"
                  class="table table-bordered table-striped table-hover table-sm"
                >
                  <thead>
                    <tr>
                      <th>
                        Nombre Ruta
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.nombre"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label>Origen</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_origen"
                          placeholder="Origen"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="origenes"
                          :filterable="false"
                          @search="buscarOrigenes"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th>
                        <label>Destino</label>
                        <v-select
                          :class="
                            $store.getters.getDarkMode ? 'dark-vselect' : ''
                          "
                          v-model="slct_destino"
                          placeholder="Destino"
                          label="nombre"
                          class="form-control form-control-sm p-0"
                          :options="destinos"
                          :filterable="false"
                          @search="buscarDestinos"
                          @input="getIndex()"
                        ></v-select>
                      </th>
                      <th style="width: 150px">
                        <label>Código</label>
                        <input
                          type="text"
                          class="form-control form-control-sm"
                          v-model="filtros.codigo"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label>Despachado A</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.despachado_a_id"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="despachados in listasForms.despachados"
                            :key="despachados.numeracion"
                            :value="despachados.numeracion"
                          >
                            {{ despachados.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="width: 150px">
                        <label>Horas de Vigencia</label>
                        <input
                          type="number"
                          class="form-control form-control-sm"
                          v-model="filtros.horas_vigencia"
                          @keyup="getIndex()"
                        />
                      </th>
                      <th>
                        <label>Tipo de ruta</label>
                        <select
                          class="form-control form-control-sm"
                          v-model="filtros.tipo_ruta"
                          @change="getIndex()"
                        >
                          <option value="">Todos...</option>
                          <option
                            v-for="tipo_ruta in listasForms.tipoRutas"
                            :key="tipo_ruta.numeracion"
                            :value="tipo_ruta.numeracion"
                          >
                            {{ tipo_ruta.descripcion }}
                          </option>
                        </select>
                      </th>
                      <th style="width: 50px"><label>Accion</label></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="ruta in rutas.data" :key="ruta.id">
                      <td>{{ ruta.nombre }}</td>
                      <td v-if="ruta.origen">{{ ruta.origen.nombre }}</td>
                      <td v-else></td>
                      <td v-if="ruta.destino">{{ ruta.destino.nombre }}</td>
                      <td v-else></td>
                      <td>{{ ruta.codigo }}</td>
                      <td class="text-center" v-if="ruta.despachado_a_id">
                        <span class="badge bg-default">{{
                          ruta.nDespachadoA
                        }}</span>
                      </td>
                      <td class="text-center" v-else>
                        <div
                          v-for="despa in ruta.rutas_despachados"
                          :key="despa.numeracion"
                        >
                          <span class="badge bg-default">
                            {{ despa.descripcion }}</span
                          >
                        </div>
                      </td>
                      <td class="text-center">{{ ruta.horas_vigencia }}</td>
                      <td class="text-center">{{ ruta.ntipo_ruta }}</td>
                      <td>
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn btn-sm bg-navy"
                            v-if="$store.getters.can('admin.rutas.show')"
                            @click="edit(ruta)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm btn-danger"
                            v-if="$store.getters.can('admin.rutas.delete')"
                            @click="destroy(ruta.id)"
                          >
                            <i class="fas fa-trash-alt"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="rutas.total">
                  <p>
                    Mostrando del <b>{{ rutas.from }}</b> al
                    <b>{{ rutas.to }}</b> de un total:
                    <b>{{ rutas.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="rutas"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  align="right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
    <RutaExport ref="RutaExport" />
  </div>
</template>

<script>
import vSelect from "vue-select";
import axios from "axios";
import pagination from "laravel-vue-pagination";
import RutaExport from "./RutaExport";

export default {
  name: "RutaIndex",
  components: {
    pagination,
    vSelect,
    RutaExport,
  },
  data() {
    return {
      file: "",
      filtros: {
        nombre: null,
        codigo: null,
        origen_id: null,
        destino_id: null,
        despachado_a_id: null,
        horas_vigencia: null,
      },
      rutas: {},
      listasForms: {
        tipoRutas: [],
        despachados: [],
      },
      origenes: [],
      destinos: [],
      slct_origen: [],
      slct_destino: [],
    };
  },
  methods: {
    getIndex(page = 1) {
      this.filtros.origen_id = null;
      if (this.slct_origen) {
        this.filtros.origen_id = this.slct_origen.id;
      }

      this.filtros.destino_id = null;
      if (this.slct_destino) {
        this.filtros.destino_id = this.slct_destino.id;
      }

      axios
        .get("/api/admin/rutas?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.rutas = response.data;
        });
    },

    destroy(idRuta) {
      this.$swal({
        title: "Esta seguro de eliminar esta Ruta?",
        text: "Los cambios no se pueden revertir!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Si, Eliminar!",
      }).then((result) => {
        if (result.value) {
          axios.delete("/api/admin/rutas/" + idRuta).then(() => {
            this.getIndex();
            this.$swal({
              icon: "success",
              title: "Se elimino la Ruta exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 4000,
              timerProgressBar: true,
            });
          });
        }
      });
    },

    buscarOrigenes(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.origenes = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarDestinos(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.destinos = respuesta;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    getDespachados() {
      axios.get("/api/lista/54").then((response) => {
        this.listasForms.despachados = response.data;
      });
    },

    create() {
      return this.$router.push({
        name: "/Admin/RutaForm",
        params: { accion: "Crear", id: null },
      });
    },

    edit(ruta) {
      return this.$router.push({
        name: "/Admin/RutaForm",
        params: { accion: "Editar", data_edit: ruta, id: ruta.id },
      });
    },
    pivotTable() {
      return this.$router.push({
        name: "/Admin/RutaPivotTable",
      });
    },

    getTipoRutas() {
      axios.get("/api/lista/48").then((response) => {
        this.listasForms.tipoRutas = response.data;
      });
    },
  },
  mounted() {
    this.getIndex();
    this.getTipoRutas();
    this.getDespachados();
  },
};
</script>
